const CONFIG = {
	currentNetwork: 'mainnet',
	mirrorNodeUrl:{
		"testnet":"https://testnet.mirrornode.hedera.com/",
		"mainnet":"https://mainnet-public.mirrornode.hedera.com/"
	},
	apiUrl:{
		"testnet":"http://localhost:1234",
		"mainnet":"https://api.hederamonkeys.com"
	},
	balanceUrl:`api/v1/tokens/0.0.2290122/balances?account.id=`,
	tokenID : '0.0.2290122',
	// balanceUrl:`api/v1/tokens/0.0.4285639/balances?account.id=`,
	// tokenID : '0.0.4285639',
	decimal:2,
	contractID:{
		"testnet":"0.0.498024",
		"mainnet":"0.0.3927013"
	},
	contractIDMint:{
		"testnet":"0.0.3927013",
		"mainnet":"0.0.4581799"
	},
	nftNames:{
        "0.0.1006183":"HEDERA MONKEY",
        "0.0.3914383":"EARLY MONKEY",
        "0.0.1071332":"VIPeel",
        "0.0.4581624":"HM X HP",
	},
	nftIDs:[
        "0.0.1006183",
        "0.0.3914383",
        "0.0.1071332",
        "0.0.4581624"
	],
	// nftNames:{
    //     "0.0.4285634":"HEDERA MONKEY",
    //     "0.0.4285635":"EARLY MONKEY",
    //     "0.0.4285636":"VIPeel",
    //     "0.0.4285637":"HM X HP",
	// },
	// nftIDs:[
	// 	"0.0.4285634",
    //     "0.0.4285635",
    //     "0.0.4285636",
    //     "0.0.4285637",
	// ],
    tokenIDMint:{
		"testnet":"0.0.7743088",
		"mainnet":"0.0.4581624"
    },
	contractID_V2:{
		"testnet":"0.0.4389024",
		"mainnet":"0.0.6028958"
	},
	nftColor:{
		"HEDERA MONKEY":"#ca9fd6",
		"EARLY MONKEY":"#7cc0d9",
        "VIPeel":"#eb2d59",
        "HM X HP":"#f79d79"
	},
	fees:200
}; 

export default CONFIG