<script>

import './App.css'
import Navbar from './components/Navbar.vue';''
import Footer from './components/Footer.vue';''

export default {

  name: "app",
  components:{
    Navbar,
    Footer}
};

</script>


<template>

      <Navbar />
      <router-view />
      <Footer />

</template>

