import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Vue3Lottie from 'vue3-lottie'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const pinia = createPinia()
const vuetify = createVuetify({
    components,
    directives,
}
)
import App from './App.vue'
import router from './router'

createApp(App).use(router).use(pinia).use(Vue3Lottie).use(vuetify).mount('#app')
