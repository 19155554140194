<script>
import '../css/Footer.css'

export default {
  name: 'Footer'
}

</script>

<template>
<div class="footer">
<div class="row">
<a href="https://discord.gg/MadFtX86sE"><i class="fa-brands fa-discord"></i></a>
<a href="https://twitter.com/HMNKYs"><i class="fa-brands fa-x-twitter"></i></a>
<a href="https://docs.hederamonkeys.com/"><i class="fa fa-book"></i></a>
<a href="https://www.youtube.com/watch?v=Cs0nma2ioAY&t=59s"><i class="fa fa-youtube"></i></a>
</div>

<div class="row">
Copyright © 2024 HEDERA MONKEYS - All rights reserved
</div>
</div>
</template>