import { defineStore } from 'pinia'

export const useAccountStore = defineStore({
  id: 'accountData',
  state: () => ({
    account: '',
    signer:'',
    status:false,
    balance:0
  }),
  getters: {
    
    getAccount: (state) => state.account,
    getSigner: (state) => state.signer,
    getStatus: (state) => state.status,
    getBalance: (state) => state.balance
  },
  actions: {
    updateAccount(account) {
      this.account = account
    },

    updateSigner(signer) {
      this.signer = signer
    },

    updateStatus(status) {
        this.status = status
    },

    updateBalance(balance){
      this.balance = balance
    }
  }
})
