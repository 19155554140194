import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/stake',
      name: 'stake',
      component: () => import('../views/StakeView.vue')
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import('../components/Gallery.vue')
    },
    // {
    //   path: '/mint',
    //   name: 'mint',
    //   component: () => import('../components/Mint.vue')
    // },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../components/404.vue')
    }
  ]
})

export default router
